<template>
  <div id="manage" class="Manage">
    <TopBar>
      <Menu v-if="isAlpha" ref="menu" :model="alphaItems" :popup="true" />
      <Menu v-else-if="isSuper" ref="menu" :model="superItems" :popup="true" />
      <Menu v-else ref="menu" :model="managerItems" :popup="true" />
      <Button
        icon="pi pi-upload"
        v-if="showImportButton"
        class="top-button p-button-rounded p-button-main p-d-flex p-ai-center p-jc-between"
        label="Import"
        @click="$router.push('/manage/creators/import')"
      >
      </Button>
      <Button
        id="v-step-1"
        :icon="exporting ? 'pi pi-spin pi-spinner' : 'pi pi-download'"
        :disabled="exporting"
        v-if="checkRouteForExport"
        class="top-button p-button-rounded p-button-main p-d-flex p-ai-center p-jc-between"
        @click="doExport"
        label="Export"
      >
      </Button>
      <Button
        id="v-step-2"
        icon="pi pi-plus"
        v-if="!readonly"
        v-model="openDropdown"
        class="top-button p-button-rounded p-button-main p-d-flex p-ai-center p-jc-between"
        label="Add New"
        @click="toggle"
      >
      </Button>
    </TopBar>
    <div class="main-container">
      <TabMenu :model="manageTabs" />
      <router-view ref="manage"></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import OmniSearchBox from "@/components/OmniSearchBox.vue";
import UserInfo from "@/components/UserInfo.vue";
import { readIsManager, readMe } from "@/store/auth/getters";
import { api } from "@/api";
import { readIsAlpha } from "@/store/auth/getters";
import { readIsSuper } from "@/store/auth/getters";
import dayjs from "dayjs";
import { downloadExport } from "@/utils";
import TopBar from "@/components/TopBar.vue";

@Component({
  components: {
    OmniSearchBox,
    UserInfo,
    TopBar,
  },
})
export default class Manage extends Vue {
  created() {
    try {
      if (this.$tours["myTour"] && this.$tours["myTour"].isRunning) {
        if (this.$route.hash === "#tour-next") this.$tours["myTour"].nextStep();
        if (this.$route.hash === "#tour-prev")
          this.$tours["myTour"].previousStep();
      }
    } catch (e) {
      console.info(e);
    }
  }

  public exporting = false;
  public searchInput = null;
  public openDropdown = false;
  public offices = [
    { name: "Hong Kong", code: "HK" },
    { name: "Philippines", code: "PH" },
    { name: "Korea", code: "KR" },
    { name: "Japan", code: "JP" },
    { name: "Indonesia", code: "ID" },
  ];
  public statuses = ["Latest Active", "Latest Inactive"];
  public manageTabs = [
    { label: "Managers", to: "/manage/managers" },
    { label: "Creators", to: "/manage/creators" },
    { label: "Manager Groups", to: "/manage/mapping-groups" },
  ];

  public alphaItems = [
    {
      label: "Manager",
      to: "/manage/managers/create",
    },
    // {
    //   label: "Manager Group",
    //   to: "/manage/mapping-groups/create"
    // },
    {
      label: "Creator",
      to: "/manage/creators/create",
    },
  ];

  public superItems = [
    {
      label: "Creator",
      to: "/manage/creators/create",
    },
    // {
    //   label: "Manager Group",
    //   to: "/manage/mapping-groups/create"
    // }
  ];

  public managerItems = [
    {
      label: "Creator",
      to: "/manage/creators/create",
    },
  ];

  get isAlpha() {
    return readIsAlpha(this.$store);
  }

  get isSuper() {
    return readIsSuper(this.$store);
  }

  get isManager() {
    return readIsManager(this.$store);
  }

  get readonly() {
    return !this.isManager;
  }

  get checkRouteForExport() {
    const routeSplit = this.$route.path.split("/").filter((item) => item);
    return ["managers", "creators", "import"].includes(
      routeSplit[routeSplit.length - 1]
    );
  }

  get showImportButton() {
    return this.isManager && this.$route.path.includes("/creators");
  }

  public toggle(event) {
    this.$refs.menu["toggle"](event);
  }

  public async doExport() {
    try {
      this.exporting = true;
      const currentTab = this.$route.path;
      const tab = currentTab.split("/")[2];
      let currentData = this.$refs.manage["$children"][0]["dataToRender"];
      if (tab == "creators")
        currentData = this.$refs.manage["filteredCreators"];
      const ids = currentData.map((item) => item.id);
      let entity;
      switch (tab) {
        case "managers":
          entity = "managers";
          break;
        case "mapping-groups":
          entity = "mapping_groups";
          break;
        case "creators":
          entity = "creators";
          break;
      }
      const response = await api.exportPage(entity, ids);
      if (response) {
        const date = dayjs().format("YYYY-MM-DD-HH-mm-ss");
        downloadExport(response, `${entity}-export-${date}.csv`);
        this["$toast"].add({
          severity: "success",
          summary: "File exported!",
          detail:
            "Import to Google Sheets or open with a text editor for optimal operations",
          life: 3000,
        });
      } else {
        this["$toast"].add({
          severity: "error",
          summary: "Something went wrong...",
          detail: "Could not export your file",
          life: 3000,
        });
      }
    } catch (e) {
    } finally {
      this.exporting = false;
    }
  }
}
</script>

<style>
.Manage .main-container {
  padding: 0.5rem;
}

.Manage .main-container .p-tabmenuitem {
  width: 12.5rem;
}

.Manage .main-container .p-menuitem-text {
  margin: auto;
}

.name-block {
  width: 11px;
  height: 38px;
  border-radius: 4px;
  margin: 29px 25px 23px 27px;
  background-color: var(--primary-1);
}

.name-text {
  height: 38px;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin: 34px 25px 23px 0;
  color: #303133;
}

.p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem
  .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
